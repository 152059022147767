import {Type} from '@angular/core';
import {DateTime} from 'luxon';
import {DateTimeOptions} from 'luxon/src/datetime';
import isUndefined from 'lodash-es/isUndefined';

const MINIMUM_PAST_DATE = '1900-01-01';

export function bindToInstance<T, P extends keyof T>(instance: T, props: {[key in P]: T[key]}): T {
  return Object.assign(instance, props);
}

export const isBoolean = (val: any): val is boolean => typeof val === 'boolean';

export const isObject = (o: any): boolean => typeof o === 'object';

export function isNumeric(num: any) {
  return !Number.isNaN(Number(num)) && num !== null; // isNumeric('') will return true
}

function isNumber(str: any) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isString(val: any) {
  return typeof val === 'string' || val instanceof String;
}

export function greaterOrEqual(min: any, max: any) {
  return isNumber(max) && isNumber(min) && (+min === +max || +min > +max);
}

export function isEmpty(val: string | any[]) {
  return val == null || val.length <= 0;
}

export const isDefined = (val: any) => val !== null && val !== undefined && (!isString(val) || val !== '');

export function findObjectByType<T extends MsApp.Dictionary>(list: any[], type: Type<T>): T | undefined {
  return list.find(item => item instanceof type);
}

export function findArray<T>(list: T[], predicate: (item: T) => boolean): T[] {
  const el = list.find(predicate);

  return el !== undefined ? [el] : [];
}

export function filterIndexes<T = any>(arr: T[], filterPredicate: (val: T) => boolean): number[] {
  return arr.reduce((indexes, el, idx) => {
    if (filterPredicate(el)) {
      indexes.push(idx);
    }

    return indexes;
  }, []);
}

function s4() {
  // eslint-disable-next-line no-bitwise
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function uniqId(prefix = 'ms-id-') {
  return `${prefix}${s4()}${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

// eslint-disable-next-line no-undef-init
export function resolve<T>(
  obj: MsApp.Dictionary,
  path: string,
  defaultValue: T | undefined = undefined
): T | undefined {
  const val = path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj) as T;

  return val !== undefined ? val : defaultValue;
}

export function setValue(obj: MsApp.Dictionary, path: string, val: any) {
  const destStorePath = path.split('.');
  const propKey = destStorePath.pop();

  const destStore = destStorePath.length > 0 ? resolve(obj, destStorePath.join('.')) : obj;

  if (typeof destStore !== 'object') {
    throw new Error('resolve() can not set value in non object property.');
  }

  destStore[propKey] = val;
}

export function toMap<T extends MsApp.Dictionary, K extends string = Extract<keyof T, string>>(
  arr: T[],
  key: K
): {[key: string]: T} {
  return arr.reduce((mapping, item) => {
    mapping[resolve<string>(item, key)] = item;

    return mapping;
  }, {});
}

export function ensureArray<T>(val: T | T[], ignoreEmpty = true): T[] {
  if (Array.isArray(val)) {
    return val;
  }

  if ((ignoreEmpty && val === undefined) || val === null) {
    return [];
  }

  return [val];
}

export function uniq<T>(arr: T[]) {
  return [...new Set(arr)];
}

export function isDateValid(val: MsApp.DateString) {
  return val && parseDateTime(val).isValid && parseDateTime(val) >= DateTime.fromISO('1900-01-01');
}

export function isDateFormatValid(val: MsApp.DateString, format: string) {
  return DateTime.fromFormat(val, format).isValid && DateTime.fromFormat(val, format) >= DateTime.fromISO('1900-01-01');
}

export function isAfter(val1: MsApp.DateString | DateTime, val2: MsApp.DateString | DateTime, needsParsing = true) {
  return needsParsing ? parseDateTime(val1) > parseDateTime(val2) : val1 > val2;
}

export const hasOwnProperty = (obj: MsApp.Dictionary<unknown>, propName: string): boolean =>
  Object.prototype.hasOwnProperty.call(obj, propName);

export const hasOwnNestedProperty = (obj: MsApp.Dictionary, propName: string, separator = '.'): boolean => {
  const path = propName.split(separator);
  let current = obj;

  return path.every(pathKey => {
    if (!isObject(current)) {
      return false;
    }

    const isOwnProp = hasOwnProperty(current, pathKey);

    if (isOwnProp) {
      current = current[pathKey];
    }

    return isOwnProp;
  });
};

export function compileTemplate(tpl: string, parameters = {}): string {
  return tpl.replace(/(\{\$([A-z0-9_.]+)\})/g, ($0, $1, $2) => (hasOwnProperty(parameters, $2) ? parameters[$2] : ''));
}

export const trim = (val: string) => (val ? val.trim() : '');

export function isDateIsToday(val: MsApp.DateString) {
  return DateTime.now().hasSame(parseDateTime(val), 'day');
}

export const isEmptyObject = (obj: Record<string, any> | null | undefined) => !obj || Object.keys(obj).length === 0;

export const isEmptyString = (val: string) => trim(val).length === 0;

export const trimTrailingZeros = (val: string): string => (+val).toString();

export const getObjectDiff = (newObj: MsApp.Dictionary, oldObj: MsApp.Dictionary): MsApp.Dictionary =>
  Object.entries(newObj).reduce((diff, [key, value]) => {
    if (!(key in oldObj) || value !== oldObj[key]) {
      diff[key] = value;
    }

    return diff;
  }, {});

export const identityMapper = <R extends Record<string, any>>(input: {[P in keyof R]: R[P]}): R => input;

export function ifNullable(val: any, replaceWith: any) {
  return val !== null && val !== undefined ? val : replaceWith;
}

export const range = (start: number, end: number) => [...Array(end - start + 1)].map((_, idx) => idx + start);

export function formatDate(date: string, formatValue = 'MM/dd/yyyy'): string {
  return parseDateTime(date, {zone: 'utc'}).toFormat(formatValue);
}

export function formatDateWithoutUTC(date: string, formatValue = 'MM/dd/yyyy'): string {
  return parseDateTime(date).toFormat(formatValue);
}

// method for converting date from UTC to Central Time
export function formatDateToCST(date: string, formatValue = 'MMM dd yyyy HH:mm A'): string {
  return parseDateTime(date, {zone: 'America/Chicago'}).toFormat(formatValue);
}

export function getFileExtension(name: string): string {
  if (!name.includes('.')) {
    return '';
  }
  return name.split('.').pop();
}

export function firstCharCapitalization(value: string): string {
  if (!value) {
    return value;
  }
  return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export function isFutureDate(val: string) {
  return parseDateTime(val).startOf('day') > DateTime.now().startOf('day');
}

export function isFutureDateTodayIncluded(val: string) {
  return parseDateTime(val).startOf('day') >= DateTime.now().startOf('day');
}

export function isSameOrFutureMonth(val: string) {
  return parseDateTime(val).startOf('month') >= DateTime.now().startOf('month');
}

export function isPastDate(val: string) {
  return parseDateTime(val) >= DateTime.fromISO(MINIMUM_PAST_DATE);
}

export function parseDateTime(val?: any, options?: DateTimeOptions): DateTime {
  if (isUndefined(val)) {
    return DateTime.now();
  }

  if (!val) {
    return DateTime.fromISO(null);
  }

  if (val instanceof Date) {
    return DateTime.fromJSDate(val, options);
  }

  if (val instanceof DateTime) {
    return DateTime.fromISO(val.toISO(), options);
  }

  if (isString(val) && /^\d{2}\/\d{2}\/\d{4}$/.test(val)) {
    return DateTime.fromFormat(val, 'MM/dd/yyyy', options);
  }

  if (isString(val) && /^\d{2}\/\d{4}$/.test(val)) {
    return DateTime.fromFormat(val, 'MM/yyyy', options);
  }

  if (isString(val) && /^\d{2}\/\d{2}$/.test(val)) {
    return DateTime.fromFormat(val, 'MM/dd', options);
  }

  if (isString(val) && /^\d{4}-\d{2}-\d{2}$/.test(val)) {
    return DateTime.fromFormat(val, 'yyyy-MM-dd', options);
  }

  if (isString(val) && /^\d{4}-\d{2}$/.test(val)) {
    return DateTime.fromFormat(val, 'yyyy-MM', options);
  }

  if (isString(val) && /^\d{2}-\d{2}$/.test(val)) {
    return DateTime.fromFormat(val, 'MM-dd', options);
  }

  if (isString(val)) {
    return DateTime.fromJSDate(new Date(val), options);
  }

  if (isNumber(val)) {
    return DateTime.fromMillis(val, options);
  }

  return DateTime.fromISO(null);
}

export function getDifferenceInMonth(from: string, to: string | number) {
  return Math.floor(parseDateTime(to).diff(parseDateTime(from), 'months').months);
}

export const getAgeInMonth = (value: string) =>
  !!value && parseDateTime(value).isValid && !isFutureDate(value) && isPastDate(value)
    ? getDifferenceInMonth(value, Date.now())
    : null;

export const getAge = (value: string) => Math.floor((getAgeInMonth(value) || 0) / 12);

export function createRegExp(expression: string) {
  const flags = expression.replace(/.*\/([gimy]*)$/, '$1');
  const pattern = expression.replace(new RegExp(`^/(.*?)/${flags}$`), '$1');
  return new RegExp(pattern, flags);
}

export function getFullName(firstName: string, lastName: string): string {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  } else if (firstName && !lastName) {
    return `${firstName}`;
  } else if (!firstName && lastName) {
    return `, ${lastName}`;
  }
  return '';
}

export function parseFullName(fullName: string): {firstName: string; lastName: string} {
  const fullNameTokens = fullName.split(', ');
  return {firstName: fullNameTokens[1], lastName: fullNameTokens[0]};
}

export function filterItems<T>(items: T[] | null, predicates: Partial<T>): T[] | null {
  if (items === null) {
    return null;
  }

  const filterPredicates = Object.entries(predicates);

  return (items || []).filter(item => filterPredicates.every(([key, val]) => item[key] === val));
}

export function toLowerCase<T extends string>(val: T): Lowercase<T> {
  return val.toLowerCase() as Lowercase<T>;
}

export function toUpperCase<T extends string>(val: T): Uppercase<T> {
  return val.toUpperCase() as Uppercase<T>;
}

/** ****************************************************************************************
 Iterates like regular forEach but inside nested objects/arrays too.
 Callback params: item, index, parent
 ***************************************************************************************** */
export function forEachInTree(
  obj: MsApp.Dictionary,
  fn: (item: unknown, key: string, parentObj: MsApp.Dictionary) => void
): void {
  if (typeof fn !== 'function') {
    throw new Error('Callback should be defined');
  }

  Object.keys(obj).forEach(key => {
    const item = obj[key];
    if (isObject(item) && !isEmpty(item)) {
      forEachInTree(item, fn);
    } else {
      fn(item, key, obj);
    }
  });
}

export const numberFormatted = (num: number): number => Math.round(num * 100) / 100;

export const roundTo2DecimalPlaces = (num: number): number => Math.round(num * 100) / 100;

export function scrollToSection(locator: string) {
  if (!locator) {
    return;
  }

  setTimeout(() => {
    const elemToScroll = document.querySelector(locator);
    if (elemToScroll) {
      elemToScroll.scrollIntoView();
    }
  }, 100);
}

export function cachingWrapper(func: (...funcArgs: any) => unknown) {
  const cache: MsApp.Dictionary<unknown> = {};

  return (...args: any) => {
    const cacheKey = JSON.stringify(args);
    if (!(cacheKey in cache)) {
      cache[cacheKey] = func(...args);
    }

    return cache[cacheKey];
  };
}

export const getBool = (val: string | boolean): boolean => (!isBoolean(val) ? !/^(false|0)$/i.test(val) && !!val : val);

/** ****************************************************************************************
 Returns 'Y'|'N' if boolean value defined (as boolean or string) or '' if undefined or empty
 ***************************************************************************************** */
export function formatDefinedBool(val: string | undefined | null | boolean): string {
  let result = '';
  if (val !== undefined && val !== null && val !== '') {
    result = getBool(val) ? 'Y' : 'N';
  }
  return result;
}

export const parseSourceType = (sourceType: string): string => {
  switch (sourceType) {
    case 'cords':
      return 'CORD';
    case 'donors':
      return 'DONOR';
    case 'abonly':
      return 'ABONLY';
    default:
      return sourceType;
  }
};

export const isPromiseLike = (value: any): boolean => value && typeof value.then === 'function';

export const isLetter = (symbol: string): boolean => /^[a-zA-Z]+$/.test(symbol);

export const isDigit = (symbol: string): boolean => /^[0-9]+$/.test(symbol);

export const isSpecial = (symbol: string): boolean => !isLetter(symbol) && !isDigit(symbol);

// eslint-disable-next-line @typescript-eslint/ban-types
export function boolMap<T extends {}, K extends string>(boolObj: T, map: Record<keyof T, K>): K[] {
  return Object.entries(boolObj || {})
    .filter(([key, enabled]) => enabled && key in map)
    .map(([key]) => map[key]);
}

export const prepareTranslateKey = (key: string): string =>
  trim(key)
    .replace(/[_\s]+/g, '_')
    .toUpperCase();

export const DEFAULT_FORMATTED_EMPTY_VALUE = '';
export const formatPid = (str: string): string => (str ? str.replace(/-/g, '') : DEFAULT_FORMATTED_EMPTY_VALUE);

export const getLazyLoadedRoute = (route: string): string => `${route}.**`;

function glueTool() {
  let result: unknown[] = [];

  const trimString = (v: any) => (typeof v === 'string' ? v.trim() : v);

  return {
    glue(joinSymbol: string, ...args: any[]) {
      result.push(args.filter(v => trimString(v)).join(joinSymbol));
      return this;
    },

    joinWith(joinSymbol: string) {
      const tmp = result.filter(v => trimString(v)).join(joinSymbol);
      result = [tmp];
      return this;
    },

    joinAll(): string {
      return result.toString();
    },
  };
}

export const formatLocation = (
  address1: string,
  address2: string,
  city: string,
  state: string,
  zipCode: string
): string =>
  glueTool().glue(' ', address1, address2).glue(', ', city).glue(' ', state, zipCode).joinWith(', ').joinAll();

export const emptyStringIfFalsy = (value?: string): string => {
  return value || '';
};

export const randInRange = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);

export const toTitleCase = (val: string): string =>
  val?.replace(/\w\S*/g, txt => txt[0].toUpperCase() + txt.slice(1).toLowerCase()) ?? '';

export function coerceBoolean(val: string | boolean): boolean {
  if (typeof val === 'string') {
    return val.toLowerCase() !== 'false';
  }

  return !!val;
}

export const formatCodeToHtml = (value: string): string => {
  if (!value) {
    return '';
  }

  return value
    .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>')
    .replace(/\n/g, '<br />')
    .replace(/\[url=([^\]]+)](.+)\[\/url]/, '<a href="$1">$2</a>')
    .replace(/\[ms-link=([^\]]+)](.+)\[\/ms-link]/, '<a class="ms-link" href="$1">$2</a>')
    .replace(
      /\[ms-link-new-tab=([^\]]+)](.+)\[\/ms-link-new-tab]/,
      '<a class="ms-link" target="_blank" href="$1">$2</a>'
    )
    .replace(/\[\^(.+?)\]/g, '<sup>$1</sup>');
};

export function blobToObject<T>(blob: Blob): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = JSON.parse(reader.result as string);
      resolve(result);
    };

    reader.onerror = () => {
      reject(blob);
    };

    reader.readAsText(blob);
  });
}

export function stringToBase64(value: string): string {
  // https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
  const bytes = new TextEncoder().encode(value);
  const binString = Array.from(bytes, byte => String.fromCodePoint(byte)).join('');
  return btoa(binString);
}
